<template>
  <div>
    <v-dialog v-model="dialog" persistent width="600" @input="openHandler">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="isNew" v-bind="attrs" v-on="on" depressed  rounded class="add-modal__btn">
          <v-icon color="#757575">mdi-plus</v-icon>
          {{ $t('add folder') }}
        </v-btn>
        <v-btn v-else v-bind="attrs" v-on="on" icon
          ><v-icon class="ml-1" color="blue">mdi-pen</v-icon></v-btn
        >
      </template>
      <v-card>
        <v-card-title class="d-flex justify-center">
          <h2>{{  isNew ? $t('Create Folder') : $t('Edit Folder') }}</h2>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-text-field v-model="folderData.name" :label="$t('folder name')" outlined dense
            color="#757575"></v-text-field>
          <v-autocomplete :label="$t('parent account')" outlined dense v-model="folderData.parent" color="#757575"
            item-text="name" item-value="id" :loading="itemsLoading" :items="folderItems" return-object no-filter
            v-debounce="fetchAutoComplete"></v-autocomplete>
          <div class="d-flex justify-center align-center">
            <v-text-field v-model="folderData.serial" :label="$t('account code')" outlined dense color="#757575"
              :disabled="!folderData.parent"></v-text-field>
            <p class="pb-2">-{{ getParentSerial }}</p>
          </div>

          <v-autocomplete :label="$t('final account')" outlined dense color="#757575" :items="types"
            v-model="folderData.type" hide-details>
          </v-autocomplete>
          <!-- <v-checkbox
            v-model="folderData.cash"
            label="الحساب نقدي"
            dense
            :true-value="1"
            :false-value="0"
            color="#757575"
          ></v-checkbox> -->
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn depressed color="blue-grey" class="white--text" @click="addFolder" :loading="submitFolderBtnLoading">{{
              isNew ? $t('storage') : $t('edit')
          }}</v-btn>
          <v-btn depressed color="primary" @click="clearData">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Notifications from "../../../../../helpers/toast-notifications";
export default {
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    folder: {
      type: Object,
    },
  },
  data() {
    return {
      folderData: {
        name: "",
        parent: {
          id: null,
        },
        serial: null,
        type: "",
        // cash: 0,
      },
      types: ["trading", "profits_and_losses", "balance"],
      dialog: false,
      itemsLoading: false,
      folderItems: [],
      parentSerial: null,
      submitFolderBtnLoading: false,
    };
  },
  computed: {
    getParentSerial() {
      return (this.parentSerial = this.folderData.parent?.serial);
    },
  },
  methods: {
    async openHandler() {
      if (this.folder && !this.isNew) {
        this.folderData.serial = this.folder.serial;
          this.folderItems.push(this.folder.parent_id);
          this.folderData.parent = this.folder.parent_id;
          const parentSerialLength = this.folder.parent_id.serial.length;
          this.folderData.serial = this.folderData.serial.slice(
            parentSerialLength,
            this.folderData.serial.length
          );
        this.folderData.type = this.folder.type;
        this.folderData.id = this.folder.id;
        this.folderData.name = this.folder.name;
        // if (this.folder.sales_account) {
        //   this.folderData.sales_account_id = this.folder.sales_account;
        //   this.salesItems.push(this.folder.sales_account);
        // }
        // if (this.folder.material_type) {
        //   this.folderData.material_type_id = this.folder.material_type;
        //   this.material_typeItems.push(this.folder.material_type);
        // }
        // if (this.folder.sales_refund_account) {
        //   this.folderData.sales_refund_account_id =
        //     this.folder.sales_refund_account;
        //   this.salesRefundItems.push(this.folder.sales_refund_account);
        // }
        // if (this.folder.purchases_account) {
        //   this.folderData.purchases_account_id = this.folder.purchases_account;
        //   this.purchasesItems.push(this.folder.purchases_account);
        // }
        // if (this.folder.purchases_refund_account) {
        //   this.folderData.purchases_refund_account_id =
        //     this.folder.purchases_refund_account;
        //   this.purchasesRefundItems.push(this.folder.purchases_refund_account);
        // }
      }
    },
    async addFolder() {

      if(this.isNew){
      try {
        this.submitFolderBtnLoading = true;
        await axios.post("/accounting/folder", {
          name: this.folderData.name,
          parent_id: this.folderData.parent.id,
          serial: this.folderData.parent.serial + this.folderData.serial,
          type: this.folderData.type,
          // cash: this.folderData.cash,
        });
        this.dialog = false;
        this.$emit("reFetchTree");
        Notifications(
          this.$t('add success'),
          { timeout: 2000, rtl: true },
          "success"
        );
      } catch (err) {
      } finally {
        this.submitFolderBtnLoading = false;
      }
    } else {
      try {
        this.submitFolderBtnLoading = true;
        await axios.put(`/accounting/folder/${this.folder.id}`, {
          name: this.folderData.name,
          parent_id: this.folderData.parent.id,
          serial: this.folderData.parent.serial + this.folderData.serial,
          type: this.folderData.type,
          // cash: this.folderData.cash,
        });
        this.dialog = false;
        this.$emit("reFetchTree");
        Notifications(
          "تمت التعديل بنجاح",
          { timeout: 2000, rtl: true },
          "success"
        );
      } catch (err) {
      } finally {
        this.submitFolderBtnLoading = false;
      }
    }
    },
    clearData(){
      this.dialog = false;
      this.folderData = {
        name: "",
        parent: {
          id: null,
        },
        serial: null,
        type: "",
        // cash: 0,
      };
    },
    async fetchAutoComplete(value) {
      try {
        if (!value) return;
        this.itemsLoading = true;
        const searchResult = await axios.get(
          "/accounting/folder/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.folderItems = searchResult.data.data;
      } catch (err) {
      } finally {
        this.itemsLoading = false;
      }
    },
  },
};
</script>

<style>
.account-specifications__add-icon {
  color: #757575;
  border: 1px solid #757575;
  border-radius: 50%;
  padding: 3px;
}

.account-specifications__add-icon:hover {
  transform: scale(1.1);
}
</style>