<template>
  <v-container >
  
    <v-col md="12">
      <v-row class="tree-view__row" @click="handleRowClick(node)">
        <v-col md="3">
          <p :style="`transform:translateX(-${depth * 40}px)`" class="tree-view__leaf-text">
            <v-icon v-if="node.model == 'ACCOUNT'">mdi-file</v-icon>
            <v-icon v-if="node.model == 'FOLDER'">mdi-menu-left</v-icon>
            <v-icon v-if="node.model == 'FOLDER'">mdi-folder</v-icon>
            <span>{{ node.serial }}- {{ $t(node.name) }}</span>
          </p>
        </v-col>
        <v-col md="2" class="d-flex justify-end">
          <span v-if="node.meta">{{ node.meta.debit.toLocaleString() }}</span>
          <span v-else>{{ $t(node.debit.toLocaleString()) }} </span>
        </v-col>
        <v-col md="2" class="d-flex justify-end">
          <span v-if="node.meta">{{ node.meta.credit.toLocaleString() }}</span>
          <span v-else>{{ $t(node.credit.toLocaleString()) }} </span>
        </v-col>
        <v-col md="2" class="d-flex justify-end">
          <span v-if="node.meta">{{ node.meta.balance.toLocaleString() }}</span>
          <span v-else>{{ $t(node.balance.toLocaleString()) }} </span>
        </v-col>
        <v-col md="3" class="d-flex justify-end" v-if="node.model != 'mah'">
          <!-- <v-btn icon><v-icon class="ml-1">mdi-eye</v-icon></v-btn> -->
          <EditAccount
            @reFetchTree="$emit('reFetchTree')"
            v-if="node.model == 'ACCOUNT'"
            :account="node"
          />
        
          <app-add-folder v-if="returnAbility('folder:store') && (node.model == 'FOLDER')" :folder="node"
            :isNew="false"  @reFetchTree="$emit('reFetchTree')"></app-add-folder>
          <deleteAccountFolder @record-deleted="$emit('reFetchTree')" :type="node.model" :id="node.id" />
        </v-col>
      </v-row>
      <v-slide-y-transition mode="out-in" group>
        <template v-if="expandedChildrenStatus">
          <TreeView v-for="child in node.children" :key="child.id + child.serial" :node="child"
            :expandChildren="child.expandChildren" :depth="depth + 1"
            @fetch-my-children="(node) => $emit('fetch-my-children', node)"
            @folderOpened="(node) => $emit('folderOpened', node)"
            @reFetchTree="(node) => $emit('reFetchTree',node)"
            >
          </TreeView>
        </template>
      </v-slide-y-transition>
    </v-col>
  
</v-container>
</template>
 
<script>

import deleteAccountFolder from './delete.vue'
import EditAccount from './editAccount.vue';
import addFolder from "./addFolder.vue";

export default {
  name: "TreeView",
  components: { appAddFolder: addFolder, deleteAccountFolder, EditAccount },
  data() {
    return {
      expandChild: this.expandChildren,
      fetchingChild: false,
    };
  },
  methods: {
    
    handleRowClick(node) {
      if (node.model == "ACCOUNT") {
        if (this.returnAbility("account:statement")) {
          this.$router.push(`/accountStatement/${node.id}`);
        }
      } else {
        this.getChildren();
      }
    },
    getChildren() {
      if (this.node.children.length == 0) {
        this.$emit("fetch-my-children", this.node);
        this.expandChild = !this.expandChild;
      } else {
        this.$emit("folderOpened", this.node);
        this.expandChild = !this.expandChild;
      }
    },
  },

  computed: {
    expandedChildrenStatus() {
      return this.expandChild;
    },
  },

  props: {
    node: Object,
    expandChildren: Boolean,
    depth: {
      type: Number,
      default: 0,
    },
  },
  created(){
    console.log(this.node);
  },
};
</script>
 
<style >
.tree-view__row {
  transition: 0.5s;
  cursor: pointer;
  border-radius: 10px;
}

.tree-view__row:hover {
  background: #c2c2c0;
}

.tree-view__leaf-text {
  font-size: 1.1rem;
  
}
</style>